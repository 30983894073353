
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { FormularioEstado } from "@/enums";
import { AuthModule, ServicioSolicitudListado } from "@/store/modules";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "servicios-listado",
  components: { "download-excel": JsonExcel },
  props: {
    servicios: Object as () => ServicioSolicitudListado[],
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const csvLabels = {
      fechaDate: t("csv.listados.servicios.fechaSolicitud"),
      nombreComarca: t("csv.solicitudes.servicios.comarca"),
      espacio: t("csv.listados.servicios.espacio"),
      responsable: t("csv.listados.servicios.responsable"),
      telefonoFamilia: t("csv.listados.servicios.telefono"),
      menoresNombres: t("csv.listados.servicios.menores"),
      estado: t("csv.listados.servicios.estado"),
    };

    const csvFields = [
      "fechaDate",
      ...(!comarcaId ? ["nombreComarca"] : []),
      "espacio",
      "responsable",
      "telefonoFamilia",
      "menoresNombres",
      "estado",
    ];
    const csvName = "serviciosListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const loadingAprobar = ref(false);

    const handleEdit = (index, row) => {
      router.push(`/listados/servicios-programados-solicitudes/${row.id}`);
    };

    const calculateLink = (record) => {
      return "/listados/servicios-programados/" + record.servicioProgramadoId;
    };

    const verFamilia = (row) => {
      router.push(`/solicitudes/familias/${row.familiaFormularioId}`);
    };

    const registros = computed(() => {
      return props.servicios?.map((row) => {
        return {
          ...row,
          telefonoFamilia:
            row.familia?.telefono || row.datosSolicitud?.telefono,
          espacio: row.servicioProgramado?.nombre,
          fechaDate: new Date(row.createdOn),
          responsable:
            (row.familia?.nombre ||
              row.peticionFormulario?.datosSolicitud?.nombre ||
              "") +
            " " +
            (row.familia?.apellidos ||
              row.peticionFormulario?.datosSolicitud?.apellidos ||
              ""),
          menoresNombres: row.datosSolicitud.menores
            ?.map((menor) => menor.nombre + " " + menor.apellidos)
            .join(", "),
          nombreComarca: row.comarca?.nombre,
        };
      });
    });

    return {
      serviciosListado: computed(() =>
        registros.value?.filter((data) => {
          return (
            !search.value ||
            textSearch(!comarcaId ? data.nombreComarca : "", search.value) ||
            textSearch(data.servicioProgramado?.nombre, search.value) ||
            textSearch(data.responsable, search.value) ||
            textSearch(data.telefonoFamilia, search.value) ||
            textSearch(data.menoresNombres, search.value) ||
            textSearch(data.estado, search.value)
          );
        })
      ),
      csvLabels,
      csvFields,
      csvName,
      loadingAprobar,
      search,
      isAdmin: !comarcaId,
      calculateLink,
      handleEdit,
      verFamilia,
      FormularioEstado,
      toLocaleDateString,
    };
  },
});
